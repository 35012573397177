/** @jsx jsx */
import { Badge, Card, jsx, Text } from "theme-ui";
import PropTypes from "prop-types";
import SrcmSearchPage from "gatsby-plugin-hfn-profile/components/SrcmSearchPage";
import SearchModelResultsAsBlob from "gatsby-plugin-hfn-profile/components/SrcmModels/SearchModelResultsAsBlob";
import {
  DefaultButton,
  ActionButton,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { convertToB64Str } from "sites-common/utils/encoded";

const groupName = (r) => r?.srcm_group_detail?.name;
const groupType = (r) => r?.srcm_group_detail?.group_type;

const colDefns = [
  ["Position", (r) => r.name],
  ["SRCM Group", (r) => `${groupName(r)} / ${groupType(r)}`],
  ["Active", (r) => (r.active ? "Yes" : "No")],
];
const blobRenderProjects = (r) => {
  const teams = [];

  ["owner_of", "member_of"].forEach((mtype) => {
    if (r[mtype]) {
      r[mtype].forEach((p) => {
        if (p.name in teams) {
          teams[p.name][mtype] = true;
        } else {
          teams[p.name] = {
            href: `/functionary-hub/team/?t=${convertToB64Str(
              JSON.stringify(p)
            )}`,
            [mtype]: true,
          };
        }
      });
    }
  });

  return (
    <div>
      <div sx={{ my: 2 }}>
        {Object.keys(teams).length === 0 ? (
          <div sx={{ p: 2, color: "grey" }}>
            No Team Ownerships / Memberships
          </div>
        ) : (
          <div>
            <Stack>
              {Object.entries(teams).map(([n, p]) => (
                <StackItem>
                  <ActionButton
                    iconProps={{ iconName: "Group" }}
                    text={n}
                    href={p.href}
                  />{" "}
                  {p.owner_of && <Badge>admin</Badge>}{" "}
                  {p.member_of && <Badge variant="info">member</Badge>}
                </StackItem>
              ))}
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

const Enclosure = ({ onHub, children }) =>
  onHub ? (
    children
  ) : (
    <Card
      sx={{
        boxShadow: "0px 3px 6px #00000029",
        opacity: 1,
        borderRadius: "2px",
        borderColor: "background",
        backgroundColor: "background",
        p: 2,
      }}
    >
      {children}
      <div sx={{ my: 2 }}>
        <DefaultButton href="/functionary-hub" text="Go to Functionary Hub" />
      </div>
    </Card>
  );
Enclosure.propTypes = {
  onHub: PropTypes.bool,
  children: PropTypes.element.isRequired,
};
Enclosure.defaultProps = {
  onHub: false,
};

const FunctionaryHub = ({ onHub }) => {
  const { srcmProfile } = useAuth();
  return (
    <div>
      <Enclosure onHub={onHub}>
        {srcmProfile?.hasPositions && (
          <div>
            <Text variant="title">My Positions</Text>

            <div sx={{ fontSize: "0.8rem", pr: 3 }}>
              <SrcmSearchPage
                reduxKey="my-positions"
                apiParams={{
                  api: `/api/v2/positions/`,
                }}
                noSearchDisplay
                colDefns={colDefns}
              />
            </div>
          </div>
        )}

        <div sx={{ my: 3 }}>
          <Text variant="title">My Teams</Text>

          <div sx={{ fontSize: "0.8rem", px: 3 }}>
            <SearchModelResultsAsBlob
              api="/api/v3/me/teams/"
              blobRender={blobRenderProjects}
            />
          </div>
        </div>
      </Enclosure>
    </div>
  );
};

FunctionaryHub.propTypes = {
  onHub: PropTypes.bool,
};
FunctionaryHub.defaultProps = {
  onHub: false,
};

export default FunctionaryHub;
