/** @jsx jsx */
import { jsx, Text } from "theme-ui";

import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { DefaultButton } from "office-ui-fabric-react";
import DefaultLayout from "../../layouts/dlw-mobile";
import FunctionaryHub from "../../components/FunctionaryHub";

const Index = (/* { data } */) => {
  const { srcmProfile } = useAuth();
  return (
    <DefaultLayout>
      <div sx={{ my: 2, p: 2 }}>
        <div sx={{ textAlign: "center" }}>
          <Text variant="header">Functionary Hub</Text>
        </div>
        <div>
          <FunctionaryHub onHub />
        </div>

        {srcmProfile?.hasPositions && (
          <DefaultButton text="All Teams" href="/functionary-hub/all-teams" />
        )}
      </div>
    </DefaultLayout>
  );
};

export default Index;
