/** @jsx jsx */
import { jsx, Alert } from "theme-ui";
import { Spinner as SpinSmall } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { useSrcmApi } from "../../fetch-srcm-api";

function SearchModelResultsAsBlob({
  api,
  methodParams,
  blobRender,
  client,
  vers,
}) {
  const apiParams = { api, methodParams, client };

  const { isFetching, data, errorMessage } = useSrcmApi(
    `${api.replaceAll("/", "")}${vers}${JSON.stringify(methodParams)}`,
    apiParams
  );
  if (isFetching) {
    return <SpinSmall />;
  }
  if (errorMessage) {
    return <Alert>{errorMessage}</Alert>;
  }
  if (data === null) {
    return <SpinSmall />;
  }

  if (blobRender) {
    return <span>{blobRender(data)}</span>;
  }
  return <span>{JSON.stringify(data)}</span>;
}

SearchModelResultsAsBlob.propTypes = {
  api: PropTypes.string.isRequired,
  methodParams: PropTypes.shape({}).isRequired,
  blobRender: PropTypes.func,
  client: PropTypes.string.isRequired,
  vers: PropTypes.number,
};
SearchModelResultsAsBlob.defaultProps = {
  blobRender: null,
  vers: 0,
};

export default SearchModelResultsAsBlob;
